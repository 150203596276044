import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaEnvelope, FaFileAlt, FaCommentDots } from 'react-icons/fa';

const ProjectsPage: React.FC = () => {
  const [activeProject, setActiveProject] = useState<string>('general-contractor');
  // Function to handle API call when a project is selected
  const handleProjectSelection = (projectName: string) => {
    setActiveProject(projectName);
    const projectData = [
      {
        name: projectName,
        description: `This is the ${projectName} project.`,
        type: projectName === 'general-contractor' ? 'EmailtoPM' : projectName === 'eng' ? 'ENG' : 'VIM',
      },
    ];
    // API call using fetch
    fetch('http://localhost:5000/api/v1/projects', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(projectData),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Failed to create project');
        })
        .then((data) => {
          console.log('Projects created successfully:', data);
          // You can add further logic to handle the response (e.g., display a success message)
        })
        .catch((error) => {
          console.error('Error creating project:', error);
          // You can add error handling logic here
        });
    };
  
  return (
    <div className="App flex justify-center items-center min-h-screen bg-gradient-to-r from-gray-100 to-blue-100">
      {/* Central Rectangular Box */}
      <aside className="w-1/3 bg-gray-900 text-white h-3/4 p-6 flex flex-col justify-between rounded-md shadow-lg">
        <div>
          <h2 className="cbase-title font-bold mb-10 text-center">Projects</h2>
          <nav>
            <Link
              to="/general-contractor"
              className={`flex items-center p-4 rounded-lg shadow-md mb-4 ${activeProject === 'general-contractor' ? 'bg-blue-600' : 'hover:bg-gray-700'}`}
              onClick={() => handleProjectSelection('general-contractor')}
            >
              <FaEnvelope className="mr-3" />
              <span>General Contractor</span>
            </Link>
            <Link
              to="/eng"
              className={`flex items-center p-4 rounded-lg shadow-md ${activeProject === 'eng' ? 'bg-blue-600' : 'hover:bg-gray-700'}`}
              onClick={() => handleProjectSelection('eng')}
            >
              <FaFileAlt className="mr-3" />
              <span>ENG</span>
            </Link>
            <Link
              to="/vim"
              className={`flex items-center p-4 rounded-lg shadow-md mb-4 ${activeProject === 'vim' ? 'bg-blue-600' : 'hover:bg-gray-700'}`}
              onClick={() => handleProjectSelection('vim')}
            >
              <FaCommentDots className="mr-3" />
              <span>VIM</span>
            </Link>
          </nav>
        </div>
      </aside>
    </div>
  );
};

export default ProjectsPage;