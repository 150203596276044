import React, { useState,useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import axios from 'axios';

function EngPage() {
  const location = useLocation();
  const [files, setFiles] = useState<File[]>([]);
  const [processing, setProcessing] = useState<string | null>(null);
  const [processedFiles, setProcessedFiles] = useState<string[]>([]);
  const [showChatbot, setShowChatbot] = useState(false);
  const [expandedFiles, setExpandedFiles] = useState<{ [key: string]: boolean }>({}); // Track expanded rows
  const [projectId, setProjectId] = useState<string | null>(null);
  const apiUrl = process.env.REACT_APP_API_URL; // Get the base URL from environment variables
  let hasFetched = false;  // Flag to prevent multiple calls
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);

  // // API call to get project details by by hardcoded project name "ENG"
  // const getProjectsByName = async () => {
  //   try {
  //     const response = await axios.get(`${apiUrl}/api/v1/projects/ENG`);
  //     const projects = response.data.projects[0]; // Assuming only one project is returned
  //     console.log('Project details fetched:', projects);

  //     if (projects && projects.id) {
  //       // Call create project API with parent_id as the fetched project_id
  //       await createProject(projects.id);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching project by name:', error);
  //   }
  // };

   // API call to create a project with parent_id as projects_id
   const createProject = async () => {
    try {
      const createProjectPayload = {
        parent_project_id: '',
        name: 'ENG',
        creator: '',
        status: 'active',
        created_at: '', 
        description: 'Extracting Submittals from Specifications'
      };

      const response = await axios.post(`${apiUrl}/api/v1/projects/project`, createProjectPayload);
      console.log('Project created successfully:', response.data);
      setProjectId(response.data.project.project_id);
    } catch (error) {
      console.error('Error creating project:', error);
    }
  };
   // On component mount, fetch project by name
  useEffect(() => {
    if (!hasFetched) {
      hasFetched = true;  // Set the flag to true after the first call
      createProject();
    }
  }, []);

  const getFilesByProject = async (projectId: string) => {
    try {
      const response = await axios.get(`${apiUrl}/api/v1/projects/${projectId}/files`);
      setFiles(response.data.files);
      return response.data.files; // Return files if found
    }
    catch (error) {     
        console.error('Error fetching files:', error);
        throw error;
    }
  };
  const uploadFileToFilesAPI = async (projectId: string, filesId: string, file: File) => {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await axios.post(`${apiUrl}/api/v1/projects/${projectId}/files/${filesId}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log('File uploaded successfully:', response.data);
      return response; // Return the response to handle in the calling code
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };
  const createFilesObject = async (projectId: string) => {
    try {
      const response = await axios.post(`${apiUrl}/api/v1/projects/${projectId}/files`);
      return response.data.files_metadata.files_id; // Return the created files_id
    } 
    catch (error) {
      console.error('Error creating files object:', error);
      throw error;
    }
  };
  
  // const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.files) {
  //     const uploadedFiles = Array.from(event.target.files);
  //     setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
  //   }
  // };
  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && projectId) {
      const uploadedFiles = Array.from(event.target.files);
      setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);

      let filesMetadata = await getFilesByProject(projectId);
      let filesId: string;

      // if (filesMetadata.length === 0) {
      //   const newFilesId = await createFilesObject(projectId);
      //   filesMetadata = { files_id: newFilesId };
      //   filesId = filesMetadata[0].files_id
      // }

      if (Array.isArray(filesMetadata) && filesMetadata.length > 0) {
        filesId = filesMetadata[0].files_id;  // Handle the case where it's an array
      }
      else if (filesMetadata && filesMetadata.files_id) {
        filesId = filesMetadata.files_id;  // Handle the case where it's an object
      }
      else {
        // Create new files object if no filesMetadata is returned
        const newFilesId = await createFilesObject(projectId);
        filesId = newFilesId;
      }
      


      const uploadedFileMetadata: any[] = [];
      for (const file of uploadedFiles) {
        const fileResponse = await uploadFileToFilesAPI(projectId,filesId, file);
        if (fileResponse && fileResponse.data.file_metadata) {
          uploadedFileMetadata.push(fileResponse.data.file_metadata);
        }
      }

      setUploadedFiles((prevFiles) => [...prevFiles, ...uploadedFileMetadata]);
    }
  };

  const handleProcessFile = (fileName: string) => {
    setProcessing(fileName);
    setTimeout(() => {
      setProcessedFiles((prevProcessedFiles) => [...prevProcessedFiles, fileName]);
      setProcessing(null);
    }, 2000);
  };

  // const handleDeleteFile = (fileName: string) => {
  //   setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  // };

  const handleDeleteFile = async (fileName: string) => {
    try {
      // Find the file by its name to get the file ID (assuming the file object contains this information)
      // const fileToDelete = files.find((file: File) => file.name === fileName);
      // Find the file by its name to get the file ID (assuming the file object contains this information)
      // type CustomFile = {
      //   name: string;
      //   file_id: string;
      //   // Other properties from the File object if necessary, or use File interface
      // };
      
      // const files: CustomFile[] = [
      //   // Your file objects should follow the CustomFile type with `file_id` included
      // ];
      // const fileToDelete = files.find((file: CustomFile) => file.name === fileName);
  
      // if (!fileToDelete) {
      //   console.error('File not found');
      //   return;
      // }
      // Ensure the file object has a file_id before proceeding
      // if (!fileToDelete) {
      //   console.error('File ID not found');
      //   return;
      // }
      // Find the file by its name to get the file ID
      const fileToDelete = uploadedFiles.find((file) => file.file_name === fileName);

      if (!fileToDelete || !fileToDelete.file_id) {
        console.error('File or File ID not found');
        return;
      }

      const fileId = fileToDelete.file_id; // Ensure you're sending the correct file ID
  
      // Call the API to delete the file
      const response = await axios.delete(`${apiUrl}/api/v1/files/${fileId}`);
      
      if (response.status === 200) {
        console.log('File deleted successfully:', response.data);
  
        // Remove the file from the UI state after successful deletion
        setUploadedFiles((prevFiles) => prevFiles.filter((file) => file.file_name !== fileName));
      } else {
        console.error('Failed to delete file:', response.data);
      }
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  const handleChatbotClick = () => {
    setShowChatbot(true);
  };

  const toggleFileDetails = (fileName: string) => {
    setExpandedFiles((prevExpandedFiles) => ({
      ...prevExpandedFiles,
      [fileName]: !prevExpandedFiles[fileName],
    }));
  };

  return (
    <div className="flex bg-gradient-to-r from-gray-100 to-blue-100 min-h-screen">
      {/* Left menu with all project names */}
      <aside className="w-1/5 bg-gray-900 text-white fixed h-full p-6 shadow-lg">
        <h2 className="text-xl font-bold mb-10">Project Names</h2>
        <ul className="space-y-6">
          <li className={`p-4 rounded-lg ${location.pathname === '/general-contractor' ? 'bg-blue-600' : 'hover:bg-gray-700'}`}>
            <Link to="/general-contractor">General Contractor</Link>
          </li>
          <li className={`p-4 rounded-lg ${location.pathname === '/eng' ? 'bg-blue-600' : 'hover:bg-gray-700'}`}>
            <Link to="/eng">Extracting Submittals from Specs</Link>
          </li>
          <li className={`p-4 rounded-lg ${location.pathname === '/vim' ? 'bg-blue-600' : 'hover:bg-gray-700'}`}>
            <Link to="/vim">VIM</Link>
          </li>
        </ul>
      </aside>

      {/* Project specific content */}
      <main className="w-4/5 ml-auto p-10 bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-gray-800">Extracting Submittals from Specifications</h2>

        {/* File upload section */}
        <div className="mt-10">
          <div className="flex items-center space-x-4">
            <label className="text-lg font-bold text-gray-800">Upload Specification Files:</label>
            <div className="relative w-fit">
              <input
                id="spec-files"
                type="file"
                accept=".pdf,.docx"
                multiple
                className="absolute inset-0 opacity-0 cursor-pointer"
                onChange={handleFileUpload}
              />
              <div className="bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700 cursor-pointer">
                Choose Files
              </div>
            </div>
          </div>
        </div>

        {/* List of uploaded files with download, process, and delete buttons */}
        {uploadedFiles.length > 0 && (
          <div className="mt-6">
            <h3 className="text-lg font-bold mb-4 text-gray-800">Files:</h3>
            <table className="table-auto w-full border-collapse border border-gray-300">
              <thead>
                <tr className="bg-gray-100">
                  <th className="border border-gray-300 px-4 py-2 text-left">File Name</th>
                  {/* <th className="border border-gray-300 px-4 py-2 text-left">File Type</th> */}
                  <th className="border border-gray-300 px-4 py-2">Action</th>
                </tr>
              </thead>
              <tbody>
                {uploadedFiles.map((file, index) => (
                  <React.Fragment key={index}>
                    <tr
                      className="hover:bg-gray-50 cursor-pointer"
                      onClick={() => toggleFileDetails(file.name)}
                    >
                      <td className="border border-gray-300 px-4 py-2">
                        <a
                          href={file.file_url}
                          download={file.file_name}
                          className="text-blue-500 underline"
                        >
                          {file.file_name}
                        </a>
                      </td>
                      {/* <td className="border border-gray-300 px-4 py-2">{file.file_type}</td> */}
                      <td className="border border-gray-300 px-4 py-2 text-center">
                        {processing === file.name ? (
                          <span className="text-gray-500">Processing...</span>
                        ) : (
                          <div className="flex justify-center items-center space-x-2">
                            <button
                              className="bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700 shadow-md"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent row click from toggling
                                handleProcessFile(file.file_name);
                              }}
                            >
                              Process to Extract Insights
                            </button>
                            <button
                              className="text-red-600 text-xl hover:text-red-700"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent row click from toggling
                                handleDeleteFile(file.file_name);
                              }}
                            >
                              ❌
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>

                    {/* Collapsible Row */}
                    {/* {expandedFiles[file.name] && (
                      <tr>
                        <td colSpan={3} className="border border-gray-300 px-4 py-2 bg-gray-50">
                          <div className="p-4">
                            <h4 className="font-semibold mb-2">File Details:</h4>
                            <p>File Size: {file.size} bytes</p>
                            <p>Last Modified: {new Date(file.lastModified).toLocaleString()}</p>
                            <p>Type: {file.type || 'Unknown'}</p>
                          </div>
                        </td>
                      </tr>
                    )} */}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* Submittal lists section */}
        {processedFiles.length > 0 && (
          <div className="mt-10">
            <h3 className="text-lg font-bold mb-4 text-gray-800">Submittals:</h3>
            <ul>
              {processedFiles.map((fileName, index) => (
                <li key={index} className="mb-4">
                  <a href={`/downloads/${fileName}`} className="text-blue-500 underline">
                    Download Processed Submittal - {fileName}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Show the chatbot button after processing at least one file */}
        {processedFiles.length > 0 && (
          <button
            className="bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700 mt-6 shadow-md"
            onClick={handleChatbotClick}
          >
            Open Chatbot
          </button>
        )}

        {/* Chatbot interface */}
        {showChatbot && (
          <div className="mt-8 bg-gray-100 p-6 rounded-md shadow-lg">
            <h3 className="text-xl font-bold mb-4 text-gray-800">Chat Interface</h3>
            <div className="chat-box bg-white p-4 rounded-md shadow-md h-64 overflow-y-auto border border-gray-300">
              <p className="text-gray-700">Chatbot: How can I assist you with the submittals?</p>
            </div>

            {/* Chat input and send button */}
            <div className="mt-4 flex items-center">
              <input
                type="text"
                className="w-full p-2 border border-gray-300 rounded-md shadow-sm"
                placeholder="Type your message..."
              />
              <button
                className="ml-2 bg-green-600 text-white p-2 rounded-full hover:bg-green-700 shadow-md"
                onClick={() => console.log('Message sent')}
              >
                &#x27A4; {/* Send arrow symbol */}
              </button>
            </div>
          </div>
        )}
      </main>
    </div>
  );
}

export default EngPage;