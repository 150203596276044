import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './LandingPage';
import ProjectsPage from './ProjectsPage';
import GeneralContractorPage from './GeneralContractorPage';
import EngPage from './EngPage';
import VimPage from './VimPage';
import './App.css';

function App() {
  return (
      <Router>
        <Routes>
          {/* Landing Page Route */}
          <Route path="/" element={<LandingPage />} />
          {/* Project Pages Routes */}
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/general-contractor" element={<GeneralContractorPage />} />
          <Route path="/eng" element={<EngPage />} />
          <Route path="/vim" element={<VimPage />} />
        </Routes>
      </Router>
  );
}

export default App;